import React from 'react'
import './about.css'
import ME from '../../asset/me-about.jpg'
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {VscFolderLibrary} from 'react-icons/vsc'

const About = () => {
  return (
    <section id='about'>
      <h5>En savoir plus</h5>
      <h2>À propos de moi</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About " />
          </div>
        </div>
      
        <div className="about__content">
          <div className="about__cards">

            <article className='about__card'>
              <FaAward className='about__icon'/>
              <h5>Expérience</h5>
              <small>1+ An de travail</small> 
            </article>

            <article className='about__card'>
              <FiUsers className='about__icon'/>
              <h5>Clients</h5>
              <small>2+</small> 
            </article>

            <article className='about__card'>
              <VscFolderLibrary className='about__icon'/>
              <h5>Projets</h5>
              <small>12+ terminés</small> 
            </article>
          </div>

          <p>
          Passionnée par les nouvelles technologies depuis que j'ai 13 ans, j'explore ce monde avec un enthousiasme inébranlable.
          Je suis actuellement en master dans une formation transversale en relation avec les 3 pôles du web : design, développement et marketing.
          J'ai développé un savoir-faire technique et une conscience digitale qui me permettent de proposer des solutions efficaces et modernes.
          </p>
          <a href="#contact" className='btn btn-primary'>Me contacter</a>
        </div>
      </div>
    </section>
  )
}

export default About