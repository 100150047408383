import React from 'react'
import './services.css'
import {BiCheck} from 'react-icons/bi'

const Services = () => {
  return (
    <section id='services'>
    <h5>Quelle est mon offre</h5>  
    <h2>Services</h2>

    <div className="container services__container">
      <article className="service">
        
        <div className="service__head">
          <h3>UX/UI Design</h3>
        </div>
       
        <ul className='service__list'>
          <li>
            <BiCheck className='service__list-icon'/>
            <p>Conception de charte graphique </p>
          </li>
          <li>
            <BiCheck className='service__list-icon'/>
            <p>Conception de Wireframe/Maquette</p>
          </li>
          <li>
            <BiCheck className='service__list-icon'/>
            <p>Mise en place d'un design system</p>
          </li>
          <li>
            <BiCheck className='service__list-icon'/>
            <p>Approche user centric </p>
          </li>
        </ul>
      </article>
      {/* FIN DE UX/UI */}
      <article className="service">
        
        <div className="service__head">
          <h3>Web Developpement</h3>
        </div>
       
        <ul className='service__list'>
          <li>
            <BiCheck className='service__list-icon'/>
            <p>Création de site vitrine</p>
          </li>
          <li>
            <BiCheck className='service__list-icon'/>
            <p>Création de site one page</p>
          </li>
          <li>
            <BiCheck className='service__list-icon'/>
            <p>Création de site WordpPress</p>
          </li>
          <li>
            <BiCheck className='service__list-icon'/>
            <p>Création de site E-Commerce Woocommerce</p>
          </li>
          <li>
            <BiCheck className='service__list-icon'/>
            <p>Création de site E-Commerce Shopify</p>
          </li>
          <li>
            <BiCheck className='service__list-icon'/>
            <p>Création de site web 100% personnalisable</p>
          </li>
        </ul>
      </article>
      {/* FIN DE WEB DEVELOPPEMENT */}


      <article className="service">
        
        <div className="service__head">
          <h3>Création de contenue</h3>
        </div>
       
        <ul className='service__list'>
          <li>
            <BiCheck className='service__list-icon'/>
            <p>Montage video</p>
          </li>
          <li>
            <BiCheck className='service__list-icon'/>
            <p>Motion design</p>
          </li>
          <li>
            <BiCheck className='service__list-icon'/>
            <p>Rélisation de clip </p>
          </li>
          <li>
            <BiCheck className='service__list-icon'/>
            <p>Modélisation 3D</p>
          </li>
         
        </ul>
      </article>
    </div>
    </section>
  )
}

export default Services