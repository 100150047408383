import React from "react";
import CV from "../../asset/CV_Romain_Monfret.pdf";

const CTA = () => {
    return (
        <div className="cta">
            <a href="#contact" className="btn btn-primary">
                Me contacter
            </a>
            <a href={CV} download className="btn">
                Télécharger CV
            </a>
        </div>
    );
};

export default CTA;
