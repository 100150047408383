import React from 'react'
import './contact.css'
import {MdOutlineEmail} from 'react-icons/md'
import {RiMessengerLine} from 'react-icons/ri'
import {BsWhatsapp} from 'react-icons/bs'
import { useRef } from 'react';
import emailjs from 'emailjs-com'


const Contact = () => {
  const form = useRef();
  const sendEmail = (e) => {
    
    e.preventDefault();
    emailjs.sendForm('service_qrxom8q', 'template_f7fb0h9', form.current, 'a7zdIkM8ahAzSn4xD')
    e.target.reset();
  };

  return (
    <section id='contact'>
    <h5>Entré en contact</h5>
    <h2>Me contacter</h2>
    <div className="container contact__container">
      <div className="contact__options">
    <article className="contact__option">
      <MdOutlineEmail className='contact__option-icon'/>
      <h4>Email</h4>
      <h5 className="text-light">romainmonfret.pro@gmail.com</h5>
      <a href="mailto:romainmonfret.pro@gmail.com" target='_blank'>Envoyer un message</a>
    </article>
    <article className="contact__option">
      <RiMessengerLine className='contact__option-icon'/>
      <h4>Messenger</h4>
      <h5 className="text-light">Romain Monfret</h5>
      <a href="https://m.me/romain.monfret.1" target='_blank'>Envoyer un message</a>
    </article>
    <article className="contact__option">
      <BsWhatsapp className='contact__option-icon'/>
      <h4>Whatsapp</h4>
      <h5 className="text-light"></h5>
      <a href="https://api.whatsapp.com/send?phone=0781566570" target='_blank'>Envoyer un message</a>
    </article>
      </div>
      {/* FIN DES OPTION */}
      <form ref={form} onSubmit={sendEmail}>

        <input type="text" name='name' placeholder='Votre Nom et Prénom' required />
        <input type="email" name='email' placeholder='Votre email' required/>
        <textarea name="message" rows="7" placeholder='Votre message' required></textarea>
        <button type='submit' className='btn btn-primary'> Envoyer le message</button>
      </form>

    </div>

    </section>
  )
}

export default Contact