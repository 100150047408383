import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {FaGithub} from 'react-icons/fa'

const HeaderSocials = () => {
  return (
    <div className='header__socials'>
        <a href="https://www.linkedin.com/in/romain-monfret-08233a1a8/" target="_blank"> <BsLinkedin /></a>
        <a href="https://github.com/PokeStar04" target="_blank"> <FaGithub /></a>
        {/* <a href="https://www.linkedin.com/in/romain-monfret-08233a1a8/" target="_blank"></a> */}
    </div>
  )
}

export default HeaderSocials